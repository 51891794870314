"use strict";

const local ='http://localhost:3000'
const cpanel ='https://interlogicglobal.com' 

exports.url  =  cpanel + '/api'; 

const CONSTANTS = {
    GETTING_INFO : "GET_INFO",
    GET_INFO_SUCCESS : "GET_INFO_SUCCESS",
    GETTING_IMG : "GET_IMG",
    GET_IMG_SUCCESS : "GET_IMG_SUCCESS",
    SET_HISTORY_OPTION : "SET_HISTORY_OPTION",
    SET_ADDED : "SET_ADDED",
    SET_STATUS : "SET_STATUS",
    GETTING_MSG : "GETTING_MSG",
    GET_MSG : "GET_MSG",
    ERROR_MSG: "Ha ocurrido un error al intentar realizar la solicitud",
    SUCCESS_MSG: "Mensaje enviado con éxito",
    ERROR_STATUS: "error",
    SUCCESS_STATUS: "success",
    ERROR_INPUTS: "Campos no válidos",
    SUCCES_SUSCRIPTION: "Suscripción exitosa",
    SET_TOKEN: "SET_TOKEN", 
    SET_IDENTITY: "SET_IDENTITY", 
}
exports.CONSTANTS = CONSTANTS;

